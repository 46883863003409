import React from "react";

import { Maybe } from "../../../../functional/maybe";
import { WinnerOfDrop } from "../../../types";
import Countdown from "./components/Countdown";
import JoinGiveaway from "./components/JoinGiveaway";
import LastWinner from "./components/LastWinner";
import {
  Container,
  Divider,
  DropInfo,
  Headline,
  ItemsLeft,
} from "./NextDrop.styles";

interface NextDropProps {
  startAt: Date;
  previousWinner: Maybe<WinnerOfDrop>;
  dropsLeft: number;
  onCountdownEnd: () => void;
}

const NextDrop: React.FC<NextDropProps> = ({
  startAt,
  previousWinner,
  dropsLeft,
  onCountdownEnd,
}: NextDropProps) => (
  <>
    <Container>
      <Headline>next item drops in</Headline>
      <DropInfo>
        <Countdown startAt={startAt} onCountdownEnd={onCountdownEnd} />
        <ItemsLeft>{dropsLeft} items left</ItemsLeft>
      </DropInfo>
    </Container>
    <Divider />
    {previousWinner ? <LastWinner winner={previousWinner} /> : <JoinGiveaway />}
  </>
);

export default NextDrop;
