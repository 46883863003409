import React, { useEffect, useRef, useState } from "react";

import { Maybe } from "../../../../functional/maybe";
import config from "../../../config";
import { GiveawayItem, WinnerOfDrop } from "../../../types";
import RevealPrize from "./components/RevealPrize";
import RevealPrizeTransition from "./components/RevealPrizeTransition";
import RevealWinner from "./components/RevealWinner";
import Roulette from "./components/Roulette";

enum RevealPhase {
  InProgress,
  RevealPrizeTransition,
  RevealPrize,
  RevealWinner,
}

interface DropProps {
  dropWinner: Maybe<WinnerOfDrop>;
  itemsLeft: GiveawayItem[];
  onRevealEnd: () => void;
}

const Drop: React.FC<DropProps> = ({
  dropWinner,
  itemsLeft,
  onRevealEnd,
}: DropProps) => {
  const timeouts = useRef<number[]>([]);
  const isLastItemLeft = itemsLeft.length === 1;

  const [revealPhase, setRevealPhase] = useState<RevealPhase>(
    RevealPhase.InProgress,
  );

  const onRouletteEnd = () => {
    const timeout = window.setTimeout(
      () => setRevealPhase(RevealPhase.RevealPrizeTransition),
      config.SHORT_REVEAL_DELAY,
    );
    timeouts.current.push(timeout);
  };
  const onTransitionOfPrizeEnd = () => {
    setRevealPhase(RevealPhase.RevealPrize);
  };
  const onRevealWinnerEnd = () => {
    const timeout = window.setTimeout(
      () => onRevealEnd(),
      config.LONG_REVEAL_DELAY,
    );
    timeouts.current.push(timeout);
  };

  useEffect(() => {
    if (isLastItemLeft && dropWinner) {
      setRevealPhase(RevealPhase.RevealPrize);
    }
  }, [dropWinner?.name]);

  useEffect(() => {
    if (revealPhase === RevealPhase.RevealPrize) {
      const timeout = window.setTimeout(
        () => setRevealPhase(RevealPhase.RevealWinner),
        config.LONG_REVEAL_DELAY,
      );
      timeouts.current.push(timeout);
    }
  }, [revealPhase]);

  useEffect(() => {
    return () => clearAllTimeouts();
  }, []);

  const clearAllTimeouts = () => {
    timeouts.current.forEach((timeout) => {
      if (timeout) window.clearTimeout(timeout);
    });
  };

  switch (revealPhase) {
    case RevealPhase.InProgress:
      if (isLastItemLeft) {
        return <RevealPrize prize={itemsLeft[0]} />;
      }

      return (
        <Roulette items={itemsLeft} winner={dropWinner} onEnd={onRouletteEnd} />
      );
    case RevealPhase.RevealPrizeTransition:
      return (
        dropWinner && (
          <RevealPrizeTransition
            prize={dropWinner.item}
            onEnd={onTransitionOfPrizeEnd}
          />
        )
      );
    case RevealPhase.RevealPrize:
      return dropWinner && <RevealPrize prize={dropWinner.item} />;
    case RevealPhase.RevealWinner:
      return (
        dropWinner && (
          <RevealWinner winner={dropWinner} onReveal={onRevealWinnerEnd} />
        )
      );
    default:
      return null;
  }
};

export default Drop;
