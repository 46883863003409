import styled from "styled-components";

import {
  SmallHeadlineText,
  SmallItemImage,
  RegularItemNameText,
  FadeInContainer,
} from "../../../../../styles";
import { COLOR } from "../../../../../variables";

export const Container = styled(FadeInContainer)`
  padding: 20px 24px 0;
`;

export const Headline = styled(SmallHeadlineText)`
  width: 130px;
  margin: 0 auto 16px;
  text-align: center;
`;

export const Details = styled.div``;

export const Name = styled.div<{ isRevealed: boolean }>`
  height: 18px;
  text-align: center;
  color: ${({ isRevealed }) => (isRevealed ? COLOR.WHITE : COLOR.GREY)};
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 24px;
  transition: color 200ms ease-in-out;
`;

export const Prize = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 4px 8px;
`;

export const PrizeImage = styled(SmallItemImage)`
  margin: 0 21px 0 0;
  flex: none;
`;

export const PrizeName = styled(RegularItemNameText)``;
