import { useEffect, useRef, useState } from "react";

import config from "../../../../../config";

export const useRevealCharacters = (
  characters: string,
): {
  revealedCharacters: string;
  isRevealed: boolean;
} => {
  const charactersArray = characters.split("");

  const [revealedCharacters, setRevealedCharacters] = useState("");
  const [isRevealed, setIsRevealed] = useState(false);

  const requestRef = useRef<number>();
  const timeoutRef = useRef<number>();
  const frame = useRef(0);

  const cycleCharacters = () => {
    timeoutRef.current = window.setTimeout(() => {
      let result = "";
      frame.current += 1;

      const charactersLength = charactersArray.length;

      for (let i = 0; i < charactersLength; i++) {
        const character = charactersArray[i];
        const currentFrame = frame.current;
        const revealFrame = i * config.REVEAL_CHARACTER_TICK;

        result +=
          currentFrame >= revealFrame ? character : getRandomCharacter();
      }

      setRevealedCharacters(result);

      requestRef.current = window.requestAnimationFrame(cycleCharacters);
    }, 1000 / 10);
  };

  const clearTimeouts = () => {
    if (requestRef.current) window.cancelAnimationFrame(requestRef.current);
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    requestRef.current = window.requestAnimationFrame(cycleCharacters);
    return () => clearTimeouts();
  }, []);

  useEffect(() => {
    if (revealedCharacters.includes(characters)) {
      setIsRevealed(true);
      clearTimeouts();
    }
  }, [revealedCharacters]);

  return { revealedCharacters, isRevealed };
};

const getRandomCharacter = (): string => {
  const characters = "abcdefghijklmnopqrstuvwxyz1234567890!@#$%^&*()-=_+";
  const charactersSet = characters.split("");

  return charactersSet[Math.floor(Math.random() * charactersSet.length)];
};
