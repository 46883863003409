import React from "react";
import { giveawayReducer, initialGiveawayState } from "./reducers";

import { GiveawayDispatch, GiveawayState } from "./types";

type GiveawayContext = {
  state: GiveawayState;
  dispatch: GiveawayDispatch;
  streamerCode: string;
};

const GiveawayStateContext = React.createContext<GiveawayContext | undefined>(
  undefined,
);

export function GiveawayProvider({
  streamerCode,
  children,
}: {
  streamerCode: string;
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = React.useReducer(
    giveawayReducer,
    initialGiveawayState,
  );
  const value = { state, dispatch, streamerCode };
  return (
    <GiveawayStateContext.Provider value={value}>
      {children}
    </GiveawayStateContext.Provider>
  );
}

export function useGiveaway(): GiveawayContext {
  const context = React.useContext(GiveawayStateContext);
  if (context === undefined) {
    throw new Error("useGiveaway must be used within a GiveawayProvider");
  }
  return context;
}
