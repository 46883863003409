import styled, { css, keyframes } from "styled-components";

import { SmallHeadlineText, LargeItemImage } from "../../../../../styles";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
`;

export const Headline = styled(SmallHeadlineText)`
  margin: 20px 0 0;
  position: relative;
  z-index: 6;
  text-align: center;
`;

export const Items = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  padding: 24px 0 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

interface LoopProps {
  isStopped: boolean;
  landingIndex: number;
  listLength: number;
}

function handleLoopAnimation({
  isStopped,
  landingIndex,
  listLength,
}: LoopProps) {
  const landingOffset = 65;
  const landingXAxis = (landingIndex || 1) * 166 - landingOffset;
  const loopDuration = listLength * 75;
  const stopDuration = loopDuration + 800;

  const loopAnimation = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
  `;

  const stopAnimation = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-${landingXAxis}px);
    }
  `;

  if (isStopped) {
    return css`
      animation: ${stopAnimation} ${stopDuration}ms
        cubic-bezier(0.2, 1.2, 0.8, 1.1) both;
    `;
  } else {
    return css`
      animation: ${loopAnimation} infinite ${loopDuration}ms
        cubic-bezier(0, 0, 1, 1) both;
    `;
  }
}

export const Loop = styled.div<LoopProps>`
  display: flex;
  flex-flow: row nowrap;
  ${(props) => handleLoopAnimation(props)};
`;

export const ItemImage = styled(LargeItemImage)`
  flex: 1 0 auto;
  margin: 0 12px;
`;
