import React from "react";

import { countdownEnd, winnerRevealed } from "../actions";
import { useGiveaway } from "../giveawayContext";
import { isWaitingForNextDrop } from "../types";
import Drop from "./components/Drop";
import Footer from "./components/Footer";
import NextDrop from "./components/NextDrop";
import { WidgetBody, WidgetBox, WidgetFooter } from "./Widget.styles";

const Widget: React.FC = () => {
  const { state, dispatch } = useGiveaway();
  const { dropPhase, nextDropAt, leftItemsToDrop, lastWinner, winner } = state;

  const onDropWinnerRevealEnd = () => dispatch(winnerRevealed());
  const onNextDropCountdownEnd = () => dispatch(countdownEnd());

  return (
    <WidgetBox data-testid="Widget">
      <WidgetBody>
        {isWaitingForNextDrop(dropPhase) ? (
          <NextDrop
            startAt={nextDropAt!}
            dropsLeft={leftItemsToDrop.length}
            previousWinner={lastWinner}
            onCountdownEnd={onNextDropCountdownEnd}
          />
        ) : (
          <Drop
            itemsLeft={leftItemsToDrop}
            dropWinner={winner}
            onRevealEnd={onDropWinnerRevealEnd}
          />
        )}
      </WidgetBody>
      <WidgetFooter>
        <Footer />
      </WidgetFooter>
    </WidgetBox>
  );
};

export default Widget;
