import useFetch, { CachePolicies } from "use-http";
import config from "./config";

const useAckNotification = (
  streamerCode: string,
): { sendAckNotification: (notificationId: string) => Promise<boolean> } => {
  const { post, response } = useFetch(config.API.PREFIX, {
    headers: { "streamer-code": streamerCode },
    cachePolicy: CachePolicies.NO_CACHE,
    retries: config.NOTIFICATION_RETRIES_NUMBER,
    retryDelay: config.NOTIFICATION_RETRY_DELAY_MILLISECONDS,
  });

  const sendAckNotification = async (notificationId: string) => {
    await post(`${config.API.PATH.ACK_NOTIFICATION}/${notificationId}`);
    return response.ok;
  };

  return { sendAckNotification };
};

export default useAckNotification;
