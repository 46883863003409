import styled, { keyframes } from "styled-components";
import config from "./config";

import { COLOR } from "./variables";

export const HeadlineText = styled.div<{ fontSize: number }>`
  color: ${COLOR.WHITE};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;

export const SmallHeadlineText = styled(HeadlineText).attrs({ fontSize: 14 })``;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR.DARK_GREY};
`;

export const createSteamImageUrl = (imageId: string): string =>
  `https://steamcommunity-a.akamaihd.net/economy/image/${imageId}/360fx360f`;

export const ItemImage = styled.div<{ imageId: string; size: number }>`
  background-image: url(${({ imageId }) => createSteamImageUrl(imageId)});
  background-repeat: no-repeat;
  background-size: cover;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const SmallItemImage = styled(ItemImage).attrs({ size: 48 })``;

export const MediumItemImage = styled(ItemImage).attrs({ size: 80 })``;

export const LargeItemImage = styled(ItemImage).attrs({ size: 142 })``;

export const ItemNameText = styled.div`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: ${COLOR.GREY};
`;

export const RegularItemNameText = styled(ItemNameText)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

export const MediumItemNameText = styled(ItemNameText)`
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
`;

export const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const FadeInContainer = styled.div`
  animation: ${fadeInAnimation} ${config.TRANSITION_DURATION}ms both;
`;

export const Box = styled.div`
  font-family: "Barlow", system-ui, -apple-system, sans-serif;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BLACK};
  overflow: hidden;
  clip-path: polygon(
    100% 0,
    100% 100%,
    calc(0% + 16px) 100%,
    0 calc(100% - 16px),
    0 0
  );
`;
