import styled, { keyframes } from "styled-components";

import { COLOR } from "../../../../../variables";

const tickerAnimation = keyframes`
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
`;

export const Container = styled.div`
  padding: 12px 0 16px;
`;

export const Headline = styled.div`
  margin: 0 0 8px;
  color: ${COLOR.WHITE};
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 0 24px;
`;

export const Info = styled.div`
  color: ${COLOR.GREY};
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
`;

export const Ticker = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  & > div {
    padding: 0 0 0 24px;
    animation: ${tickerAnimation} infinite 6s linear;
    white-space: nowrap;
  }
`;
