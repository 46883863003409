import React, { useEffect } from "react";

import { giveawayEnd, initializeLiveGiveaway } from "./actions";
import { useGiveaway } from "./giveawayContext";
import handleSocketNotification from "./handleSocketNotification";
import useAckNotification from "./useAckNotification";
import useLiveGiveawayData from "./useLiveGiveawayData";
import useSocketNotification from "./useSocketNotification";
import Widget from "./Widget";
import { WidgetCanvas, WidgetFade } from "./Widget/Widget.styles";

const Giveaway: React.FC = () => {
  const { state, dispatch, streamerCode } = useGiveaway();
  const { liveGiveawayData } = useLiveGiveawayData(streamerCode);
  const { lastNotification } = useSocketNotification(streamerCode);
  const { sendAckNotification } = useAckNotification(streamerCode);

  useEffect(() => {
    if (liveGiveawayData) {
      dispatch(initializeLiveGiveaway(liveGiveawayData));
    }
  }, [liveGiveawayData]);

  useEffect(() => {
    if (lastNotification) {
      handleSocketNotification(
        lastNotification,
        state.winnerPhase,
        dispatch,
        sendAckNotification,
      );
    }
  }, [lastNotification, state.winnerPhase]);

  return (
    <WidgetCanvas data-testid="WidgetCanvas">
      <WidgetFade
        show={state.isGiveawayLive}
        onExitEnd={() => dispatch(giveawayEnd())}
      >
        <Widget />
      </WidgetFade>
    </WidgetCanvas>
  );
};

export default Giveaway;
