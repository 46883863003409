import styled from "styled-components";

import { FadeInContainer } from "../../../styles";
import { COLOR } from "../../../variables";

export const Container = styled(FadeInContainer)`
  padding: 20px 24px 8px;
`;

export const Headline = styled.div`
  color: ${COLOR.WHITE};
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;

export const DropInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
`;

export const ItemsLeft = styled.div`
  color: ${COLOR.GREY};
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-align: right;
`;

export const Divider = styled.div`
  margin: 0 24px;
  height: 1px;
  background-color: ${COLOR.DARK_GREY};
`;
