const CONFIG = {
  API: {
    PREFIX: "/api/v1",
    PATH: {
      GET_LIVE_GIVEAWAY_STATE: "/get-live-giveaway-state-for-streamer",
      ACK_NOTIFICATION: "/ack-notification",
    },
  },
  REVEAL_CHARACTER_TICK: 2,
  ROULETTE_MAX_LENGTH: 8,
  SHORT_REVEAL_DELAY: 1000,
  LONG_REVEAL_DELAY: 3000,
  TRANSITION_DURATION: 300,
  SECONDS_TO_HIGHLIGHT_COUNT_END: 10,
  NOTIFICATION_RETRIES_NUMBER: 666,
  NOTIFICATION_RETRY_DELAY_MILLISECONDS: 3000, // 3s
};

export default CONFIG;
