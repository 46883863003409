import React from "react";
import { hot } from "react-hot-loader";

import { isNil } from "../functional/logic";
import Giveaway from "./Giveaway";
import { GiveawayProvider } from "./giveawayContext";
import useStreamerCodeFromHash from "./useStreamerCodeFromHash";

const App: React.FC = () => {
  const { streamerCode } = useStreamerCodeFromHash();

  if (isNil(streamerCode)) return null;

  return (
    <GiveawayProvider streamerCode={streamerCode}>
      <Giveaway />
    </GiveawayProvider>
  );
};

export default hot(module)(App);
