import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import config from "../config";
import { Box } from "../styles";
import { COLOR } from "../variables";

export const WidgetCanvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  backface-visibility: hidden;
  transform: scale(1);
`;

export const WidgetBox = styled(Box)`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-flow: column nowrap;
  width: 296px;
  height: 224px;
  backface-visibility: hidden;
`;

export const WidgetBody = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: hidden;
  will-change: contents;
`;

export const WidgetFooter = styled.div`
  position: relative;
  z-index: 2;
  flex: none;
  display: flex;
  align-items: center;
  height: 60px;
  border-top: 1px solid ${COLOR.DARK_GREY};
  padding: 16px 24px 20px;
`;

interface WidgetFadeProps {
  show: boolean;
  onExitEnd: () => void;
  children?: JSX.Element;
}

export const WidgetFade: React.FC<WidgetFadeProps> = ({
  show,
  onExitEnd,
  children,
}: WidgetFadeProps) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);

    const timeout = setTimeout(() => {
      if (!show) {
        setRender(false);
        onExitEnd();
      }
    }, config.TRANSITION_DURATION);
    return () => clearTimeout(timeout);
  }, [show]);

  return render ? (
    <WidgetFadeAnimation show={show}>{children}</WidgetFadeAnimation>
  ) : null;
};

export const WidgetFadeAnimation = styled.div<{ show: boolean }>`
  ${(props) => handleFadeAnimation(props.show)};
`;

function handleFadeAnimation(show: boolean) {
  const fadeInAnimation = keyframes`
    0% {
      opacity: 0;
      transform: translateX(50px);
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  `;

  const fadeOutAnimation = keyframes`
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(50px);
    }
    `;

  if (show) {
    return css`
      animation: ${fadeInAnimation} ${config.TRANSITION_DURATION}ms ease-out;
    `;
  } else {
    return css`
      animation: ${fadeOutAnimation} ${config.TRANSITION_DURATION}ms ease-in;
    `;
  }
}
