import styled, { keyframes } from "styled-components";

import { SmallHeadlineText } from "../../../../../styles";
import { COLOR } from "../../../../../variables";

const tickerAnimation = keyframes`
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
`;

export const Container = styled.div`
  padding: 12px 0 16px;
`;

export const Headline = styled(SmallHeadlineText)`
  padding: 0 24px;
  margin: 0 0 8px;
`;

export const Ticker = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  & > div {
    animation: 6s ${tickerAnimation} infinite 300ms linear;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  white-space: nowrap;
  padding: 0 0 0 24px;
`;

export const Nickname = styled.div`
  color: ${COLOR.WHITE};
`;

export const Divider = styled.div`
  margin: 0 8px;
  color: ${COLOR.GREY};
`;

export const PrizeName = styled.div`
  color: ${COLOR.GREY};
`;
