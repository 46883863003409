import { useEffect, useState } from "react";
import useFetch, { CachePolicies } from "use-http";
import { Maybe } from "../functional/maybe";
import config from "./config";
import { DropData } from "./types";

const useLiveGiveawayData = (
  streamerCode: string,
): { liveGiveawayData: Maybe<DropData> } => {
  const [liveGiveawayData, setLiveGiveawayData] =
    useState<Maybe<DropData>>(null);

  const { get, response } = useFetch(config.API.PREFIX, {
    headers: { "streamer-code": streamerCode },
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const fetchInitialGiveawayData = async () => {
    await get(config.API.PATH.GET_LIVE_GIVEAWAY_STATE);

    if (response.ok) {
      if (response.data.status === "Success") {
        setLiveGiveawayData(response.data.result as DropData);
      }
    }
  };

  useEffect(() => {
    fetchInitialGiveawayData();
  }, []);

  return { liveGiveawayData };
};

export default useLiveGiveawayData;
