import React from "react";

import { Container, Headline, Ticker, Info } from "./JoinGiveaway.styles";

const JoinGiveaway: React.FC = () => (
  <Container>
    <Headline>Join the Giveaway</Headline>
    <Ticker>
      <Info>!Skinwallet for more info</Info>
      <Info>!Skinwallet for more info</Info>
      <Info>!Skinwallet for more info</Info>
    </Ticker>
  </Container>
);

export default JoinGiveaway;
