import {
  initializeEndingOfGiveaway,
  initializeLiveGiveaway,
  initializeNextDrop,
  setPickedWinner,
} from "./actions";
import {
  GiveawayDispatch,
  GiveawayDropNotification,
  GiveawayEndNotification,
  WINNER_PHASE,
  NOTIFICATION_TYPE,
  Notification,
  WinnerNotification,
  isWaitingForWinner,
  isWinnerReveled,
} from "./types";

const handleSocketNotification = async (
  notification: Notification,
  winnerPhase: WINNER_PHASE,
  dispatch: GiveawayDispatch,
  sendAckNotification: (notificationId: string) => Promise<boolean>,
): Promise<void> => {
  const onGiveawayStartedNotification = async (
    notification: GiveawayDropNotification,
  ) => {
    if (isWaitingForWinner(winnerPhase)) {
      const wasAck = await sendAckNotification(notification.notificationId);

      if (wasAck) {
        dispatch(initializeLiveGiveaway(notification.data));
      }
    }
  };

  const onNextDropNotification = async (
    notification: GiveawayDropNotification,
  ) => {
    if (isWinnerReveled(winnerPhase)) {
      const wasAck = await sendAckNotification(notification.notificationId);

      if (wasAck) {
        dispatch(initializeNextDrop(notification.data));
      }
    }
  };

  const onWinnerPickedNotification = async (
    notification: WinnerNotification,
  ) => {
    if (isWaitingForWinner(winnerPhase)) {
      dispatch(setPickedWinner(notification.data));
    } else if (isWinnerReveled(winnerPhase)) {
      await sendAckNotification(notification.notificationId);
    }
  };

  const onGiveawayEndedNotification = async (
    notification: GiveawayEndNotification,
  ) => {
    if (isWaitingForWinner(winnerPhase) || isWinnerReveled(winnerPhase)) {
      const wasAck = await sendAckNotification(notification.notificationId);

      if (wasAck) {
        dispatch(initializeEndingOfGiveaway());
      }
    }
  };

  switch (notification.type) {
    case NOTIFICATION_TYPE.GIVEAWAY_STARTED:
      await onGiveawayStartedNotification(notification);
      break;

    case NOTIFICATION_TYPE.NEXT_DROP:
      await onNextDropNotification(notification);
      break;

    case NOTIFICATION_TYPE.WINNER_PICKED:
      await onWinnerPickedNotification(notification);
      break;

    case NOTIFICATION_TYPE.GIVEAWAY_ENDED:
      await onGiveawayEndedNotification(notification);
      break;
  }
};

export default handleSocketNotification;
