import { pipe } from './function';
import { curry } from './curry';
import { isNil } from './logic';
import { Maybe, toMaybe } from './maybe';

export function map<A, B>(fn: (v: A) => B, arr: A[]): B[];
export function map<A, B>(fn: (v: A) => B): (arr: A[]) => B[];
export function map(...args: any): any {
    return (curry((fn: any, arr: any[]): any[] => arr.map(fn)) as any)(...args);
}

export function mapIndexed<A, B>(fn: (v: A, index: number) => B, arr: A[]): B[];
export function mapIndexed<A, B>(fn: (v: A, index: number) => B): (arr: A[]) => B[];
export function mapIndexed(...args: any): any {
    return (curry((fn: any, arr: any[]): any[] => arr.map(fn)) as any)(...args);
}

export function filter<T>(fn: (v: T) => boolean, vs: T[]): T[];
export function filter<T>(fn: (v: T) => boolean): (vs: T[]) => T[];
export function filter(...args: any): any {
    return (curry((fn: any, arr: any[]): any[] => arr.filter(fn)) as any)(...args);
}

export const find = <T>(predicate: (v: T) => boolean) => (vs: T[]): Maybe<T> => vs.find(predicate) || null;

export const mapTo = <T>(value: T) => (arr: unknown[]): T[] => arr.map((_) => value);

export function reduce<T, V>(fn: (acc: T, curr: V, index: number) => T, initialValue: T, arr: V[]): T;
export function reduce<T, V>(fn: (acc: T, curr: V, index: number) => T, initialValue: T): (arr: V[]) => T;
export function reduce(...args: any): any {
    return (curry((fn: any, initialValue: any, arr: any) => arr.reduce(fn, initialValue)) as any)(...args);
}

export const head = <T>(arr: T[]): Maybe<T> => toMaybe(arr[0]);
export const pop = <T>(arr: T[]): [Maybe<T>, T[]] => [head(arr), arr.slice(1)];
export const last = <T>(arr: T[]): Maybe<T> => toMaybe(arr[arr.length - 1]);

export const values = <V>(obj: { [key: string]: V }): V[] => Object.values(obj);
export const entries = <V>(obj: { [key: string]: V }): [string, V][] => Object.entries(obj);
export const objFromEntries = <V>(entries: [string, V][]): { [key: string]: V } =>
    reduce(
        (obj: { [key: string]: V }, [key, value]: [string, V]) => ({
            ...obj,
            [key]: value,
        }),
        {}
    )(entries);
export const mapObject = <V, V2>(fn: (v: V) => V2, obj: { [key: string]: V }): { [key: string]: V2 } =>
    pipe(
        obj,
        entries,
        map(([key, value]) => [key, fn(value)] as [string, V2]),
        objFromEntries
    );

export function concat<T>(arr1: T[], arr2: T[]): T[];
export function concat<T>(arr1: T[]): (arr2: T[]) => T[];
export function concat(...args: any): any {
    return (curry((a1: any[], a2: any[]): any[] => a1.concat(a2)) as any)(...args);
}

export const flatten = <T>(arr: T[][]): T[] => reduce((flattened: T[], xs: T[]): T[] => concat(flattened, xs), [])(arr);

const rangeRecursive = (values: number[], until: number): number[] =>
    values.length === until ? values : rangeRecursive(pipe(values, concat([values.length])), until);
export const range = (until: number) => rangeRecursive([], until);

export const length = <T>(arr: T[]): number => arr.length;
export const isEmpty = (arr: unknown[]): boolean => arr.length === 0;

export function append<V>(v: V, vs: V[]): V[];
export function append<V>(v: V): (vs: V[]) => V[];
export function append(...args: any): any {
    return (curry((v: any, vs: any[]): any => [...vs, v]) as any)(...args);
}

export function prepend<V>(v: V, vs: V[]): V[];
export function prepend<V>(v: V): (vs: V[]) => V[];
export function prepend(...args: any): any {
    return (curry((v: any, vs: any[]): any => [v, ...vs]) as any)(...args);
}

export const updateWhere = <V>(wherePredicate: (v: V) => boolean, updateFn: (v: V) => V, arr: V[]): V[] => {
    const index = arr.findIndex(wherePredicate);
    return [...arr.slice(0, index), updateFn(arr[index]), ...arr.slice(index + 1)];
};

export const filterNotNil = <T>(arr: (T | null | undefined)[]): T[] =>
    arr.filter((v: T | null | undefined): v is T => !isNil(v));

export const appendToSet = <T>(v: T, vs: Set<T>): Set<T> => new Set([...vs, v]);
export const removeFromSet = <T>(v: T, vs: Set<T>): Set<T> => {
    const set = new Set([...vs]);
    set.delete(v);
    return set;
};

export const getOtherFromPair = <T>(otherThan: T, pair: [T, T]): T => (otherThan === pair[0] ? pair[1] : pair[0]);

export const join = curry(<T>(separator: string, arr: T[]) => arr.join(separator));

type hasKey<T> = { [K in keyof T]: T[K] };
export const countBy = <T extends hasKey<T>>(
    toReduce: T[],
    byKey: keyof hasKey<T>
): { [byKey in string | number]: number } =>
    toReduce.reduce(
        (prev, curr) =>
            curr[byKey] in prev ? { ...prev, [curr[byKey]]: prev[curr[byKey]] + 1 } : { ...prev, [curr[byKey]]: 1 },
        {}
    );

export const resize = <A, B extends A>(arr: A[], newSize: number, defaultValue: B): A[] => [
    ...arr,
    ...Array(Math.max(newSize - arr.length, 0)).fill(defaultValue),
];
