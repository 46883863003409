import styled, { keyframes } from "styled-components";

import config from "../../../../../config";
import {
  MediumItemImage,
  MediumItemNameText,
  SmallHeadlineText,
  fadeInAnimation,
  fadeOutAnimation,
} from "../../../../../styles";

const transitionImageAnimation = keyframes`
  0% {
    top: -10px;
    width: 142px;
    height: 142px;
  }
  100% {
    top: 0;
    width: 80px;
    height: 80px;
  }
`;

export const Container = styled.div`
  padding: 20px 24px 0;
`;

export const Headline = styled(SmallHeadlineText)`
  position: relative;
  text-align: center;
`;

export const HeadlineFadeIn = styled.span`
  animation: ${config.TRANSITION_DURATION}ms ${fadeInAnimation}
    ${config.TRANSITION_DURATION}ms both;
`;

export const HeadlineFadeOut = styled.span`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  animation: ${fadeOutAnimation} ${config.TRANSITION_DURATION}ms both;
`;

export const Image = styled(MediumItemImage)`
  position: relative;
  margin: 0 auto;
  animation: ${transitionImageAnimation} ${config.TRANSITION_DURATION * 2}ms
    both;
`;

export const Name = styled(MediumItemNameText)`
  text-align: center;
  animation: ${config.TRANSITION_DURATION}ms ${fadeInAnimation}
    ${config.TRANSITION_DURATION}ms both;
`;
