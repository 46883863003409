import { isNil } from './logic';

export type Maybe<T> = T | null;

export const toMaybe = <T>(v: T): Maybe<T> => (isNil(v) ? null : v);

export const maybeMap = <A, B>(fn: (v: A) => B) => (maybe: Maybe<A>): Maybe<B> => (isNil(maybe) ? maybe : fn(maybe));

export const maybeToBool = <A>(maybe: Maybe<A>): boolean => (isNil(maybe) ? false : !!maybe);

export const maybeWithDefault = <V>(defaultValue: V) => (maybe: Maybe<V>): V => (isNil(maybe) ? defaultValue : maybe);

export const flattenMaybes = <T>(v: Maybe<Maybe<T>>): Maybe<T> => v;
