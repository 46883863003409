import React from "react";

import { GiveawayItem, WinnerOfDrop } from "../../../../../types";
import {
  Container,
  Headline,
  Details,
  Divider,
  PrizeName,
  Nickname,
  Ticker,
} from "./LastWinner.styles";

interface WinnerDetailsProps {
  name: string;
  prize: GiveawayItem;
}

const WinnerDetails: React.FC<WinnerDetailsProps> = ({
  name,
  prize,
}: WinnerDetailsProps) => (
  <Details>
    <Nickname>{name}</Nickname>
    <Divider>{"•"}</Divider>
    <PrizeName>{prize.marketHashName}</PrizeName>
  </Details>
);

interface LastWinnerProps {
  winner: WinnerOfDrop;
}

const LastWinner: React.FC<LastWinnerProps> = ({ winner }: LastWinnerProps) => (
  <Container>
    <Headline>last winner</Headline>
    <Ticker>
      <WinnerDetails name={winner.name} prize={winner.item} />
      {/* for animation */}
      <WinnerDetails name={winner.name} prize={winner.item} />
      <WinnerDetails name={winner.name} prize={winner.item} />
    </Ticker>
  </Container>
);

export default LastWinner;
