import { useEffect, useState } from "react";
import { Maybe } from "../functional/maybe";

const useStreamerCodeFromHash = (): { streamerCode: Maybe<string> } => {
  const [streamerCode, setStreamerCode] = useState<Maybe<string>>(null);

  useEffect(() => {
    const hash = window.location.hash;
    const codeFromHash = hash === "" ? null : hash.substring(1);
    setStreamerCode(codeFromHash);
  }, []);

  return { streamerCode };
};

export default useStreamerCodeFromHash;
