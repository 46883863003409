import React, { useEffect, useState } from "react";

import config from "../../../../../config";
import { COLOR } from "../../../../../variables";
import { Timer } from "./Countdown.styles";
import useCountdown from "./useCountdown";

function secondsToFormattedTime(sec: number): string {
  const duration = Math.max(0, sec);

  const hours = Math.floor((duration / 3600) % 24);
  const minutes = Math.floor((duration / 60) % 60);
  const seconds = Math.floor(duration % 60);

  const h = hours.toString();
  const m = minutes.toString().padStart(2, "0");
  const s = seconds.toString().padStart(2, "0");

  return `${h}:${m}:${s}`;
}

interface CountdownProps {
  startAt: Date;
  onCountdownEnd: () => void;
}

const Countdown: React.FC<CountdownProps> = ({
  startAt,
  onCountdownEnd,
}: CountdownProps) => {
  const { seconds, start, stop } = useCountdown(startAt);
  const [timerColor, setTimerColor] = useState<COLOR>();

  useEffect(() => {
    start();
    return () => stop();
  }, []);

  useEffect(() => {
    if (seconds <= config.SECONDS_TO_HIGHLIGHT_COUNT_END) {
      setTimerColor(COLOR.RED);
    }

    if (seconds < 0) {
      stop();
      onCountdownEnd();
    }
  }, [seconds]);

  const formattedTime = secondsToFormattedTime(seconds);

  return <Timer fontColor={timerColor}>{formattedTime}</Timer>;
};

export default Countdown;
