import React from "react";

import { GiveawayItem } from "../../../../../types";
import {
  Container,
  Headline,
  HeadlineFadeIn,
  HeadlineFadeOut,
  Image,
  Name,
} from "./RevealPrizeTransition.styles";

interface RevealPrizeTransitionProps {
  prize: GiveawayItem;
  onEnd: () => void;
}

const RevealPrizeTransition: React.FC<RevealPrizeTransitionProps> = ({
  prize,
  onEnd,
}: RevealPrizeTransitionProps) => {
  const { marketHashName, imageSteamId } = prize;

  return (
    <Container>
      <Headline>
        <HeadlineFadeOut>dropping in progress...</HeadlineFadeOut>
        <HeadlineFadeIn>the prize item is</HeadlineFadeIn>
      </Headline>
      <Image imageId={imageSteamId} onAnimationEnd={onEnd} />
      <Name>{marketHashName}</Name>
    </Container>
  );
};

export default RevealPrizeTransition;
