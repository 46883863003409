import { createSteamImageUrl } from "./styles";
import {
  DropData,
  GIVEAWAY_ACTION,
  GiveawayEndedAction,
  GiveawayInitializeAction,
  NextDropAction,
  WinnerOfDrop,
  WinnerPickAction,
  WaitingForWinnerAction,
  WinnerRevealedAction,
  GiveawayEndingAction,
  GiveawayItem,
} from "./types";

export const initializeLiveGiveaway = (
  data: DropData,
): GiveawayInitializeAction => {
  preFetchGiveawayImages(data.leftItemsToDrop);

  return {
    type: GIVEAWAY_ACTION.STARTED,
    nextDropAt: data.nextDropAt,
    leftItemsToDrop: data.leftItemsToDrop,
    lastWinner: data.lastWinnerOfItem,
  };
};

export const setPickedWinner = (winner: WinnerOfDrop): WinnerPickAction => ({
  type: GIVEAWAY_ACTION.WINNER_PICK,
  winner,
});

export const initializeNextDrop = (data: DropData): NextDropAction => ({
  type: GIVEAWAY_ACTION.NEXT_DROP,
  nextDropAt: data.nextDropAt,
  leftItemsToDrop: data.leftItemsToDrop,
  lastWinner: data.lastWinnerOfItem,
});

export const initializeEndingOfGiveaway = (): GiveawayEndingAction => ({
  type: GIVEAWAY_ACTION.ENDING,
});

export const giveawayEnd = (): GiveawayEndedAction => ({
  type: GIVEAWAY_ACTION.ENDED,
});

export const countdownEnd = (): WaitingForWinnerAction => ({
  type: GIVEAWAY_ACTION.COUNTDOWN_END,
});

export const winnerRevealed = (): WinnerRevealedAction => ({
  type: GIVEAWAY_ACTION.WINNER_REVEALED,
});

const preFetchGiveawayImages = (items: GiveawayItem[]) => {
  for (let index = 0; index < items.length; index++) {
    const img = new Image();
    img.src = createSteamImageUrl(items[index].imageSteamId);
  }
};
