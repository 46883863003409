import {
  GIVEAWAY_ACTION,
  GiveawayState,
  GiveawayActionType,
  WINNER_PHASE,
  DROP_PHASE,
} from "./types";

export const initialGiveawayState: GiveawayState = {
  isGiveawayLive: false,
  dropPhase: DROP_PHASE.WAITING_FOR_NEXT_DROP,
  winnerPhase: WINNER_PHASE.WAITING_FOR_WINNER,
  nextDropAt: null,
  leftItemsToDrop: [],
  lastWinner: null,
  winner: null,
};

export const giveawayReducer = (
  state = initialGiveawayState,
  action: GiveawayActionType,
): GiveawayState => {
  switch (action.type) {
    case GIVEAWAY_ACTION.STARTED:
      return {
        ...state,
        isGiveawayLive: true,
        dropPhase: DROP_PHASE.WAITING_FOR_NEXT_DROP,
        winnerPhase: WINNER_PHASE.WAITING_FOR_WINNER,
        nextDropAt: action.nextDropAt,
        leftItemsToDrop: action.leftItemsToDrop,
        lastWinner: action.lastWinner,
      };
    case GIVEAWAY_ACTION.NEXT_DROP:
      return {
        ...state,
        dropPhase: DROP_PHASE.WAITING_FOR_NEXT_DROP,
        winnerPhase: WINNER_PHASE.WAITING_FOR_WINNER,
        nextDropAt: action.nextDropAt,
        leftItemsToDrop: action.leftItemsToDrop,
        lastWinner: action.lastWinner,
        winner: null,
      };
    case GIVEAWAY_ACTION.COUNTDOWN_END:
      return {
        ...state,
        dropPhase: DROP_PHASE.DROP_IN_PROGRESS,
      };
    case GIVEAWAY_ACTION.WINNER_PICK:
      return {
        ...state,
        winnerPhase: WINNER_PHASE.WINNER_PICKED,
        winner: action.winner,
      };
    case GIVEAWAY_ACTION.WINNER_REVEALED:
      return {
        ...state,
        winnerPhase: WINNER_PHASE.WINNER_REVEALED,
        dropPhase: DROP_PHASE.DROP_FINISHED,
      };
    case GIVEAWAY_ACTION.ENDING:
      return {
        ...state,
        isGiveawayLive: false,
      };
    case GIVEAWAY_ACTION.ENDED:
      return initialGiveawayState;
    default:
      return state;
  }
};
