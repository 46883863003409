import React, { useEffect } from "react";

import { HorizontalDivider } from "../../../../../styles";
import { WinnerOfDrop } from "../../../../../types";
import {
  Container,
  Details,
  Headline,
  Name,
  Prize,
  PrizeImage,
  PrizeName,
} from "./RevealWinner.styles";
import { useRevealCharacters } from "./useRevealCharacters";

interface RevealWinnerProps {
  winner: WinnerOfDrop;
  onReveal: () => void;
}

const RevealWinner: React.FC<RevealWinnerProps> = ({
  winner,
  onReveal,
}: RevealWinnerProps) => {
  const { revealedCharacters, isRevealed } = useRevealCharacters(winner.name);

  useEffect(() => {
    if (isRevealed) onReveal();
  }, [isRevealed]);

  return (
    <Container>
      <Headline>and a shiny new skin goes to</Headline>
      <Details>
        <Name isRevealed={isRevealed}>{revealedCharacters}</Name>
        <HorizontalDivider />
        <Prize>
          <PrizeImage imageId={winner.item.imageSteamId} />
          <PrizeName>{winner.item.marketHashName}</PrizeName>
        </Prize>
      </Details>
    </Container>
  );
};

export default RevealWinner;
