import { Maybe } from "../functional/maybe";

export enum GIVEAWAY_ACTION {
  STARTED = "STARTED",
  NEXT_DROP = "NEXT_DROP",
  COUNTDOWN_END = "COUNTDOWN_END",
  WINNER_PICK = "WINNER_PICK",
  WINNER_REVEALED = "WINNER_REVEALED",
  ENDING = "ENDING",
  ENDED = "ENDED",
}

export enum WINNER_PHASE {
  WAITING_FOR_WINNER = "WAITING_FOR_WINNER",
  WINNER_PICKED = "WINNER_PICKED",
  WINNER_REVEALED = "WINNER_REVEALED",
}

export const isWinnerPicked = (winnerPhase: WINNER_PHASE): boolean =>
  winnerPhase === WINNER_PHASE.WINNER_PICKED;
export const isWinnerReveled = (winnerPhase: WINNER_PHASE): boolean =>
  winnerPhase === WINNER_PHASE.WINNER_REVEALED;
export const isWaitingForWinner = (winnerPhase: WINNER_PHASE): boolean =>
  winnerPhase === WINNER_PHASE.WAITING_FOR_WINNER;

export enum DROP_PHASE {
  WAITING_FOR_NEXT_DROP = "WAITING_FOR_NEXT_DROP",
  DROP_IN_PROGRESS = "DROP_IN_PROGRESS",
  DROP_FINISHED = "DROP_FINISHED",
}

export const isWaitingForNextDrop = (dropPhase: DROP_PHASE): boolean =>
  dropPhase === DROP_PHASE.WAITING_FOR_NEXT_DROP;
export const isDropInProgress = (dropPhase: DROP_PHASE): boolean =>
  dropPhase === DROP_PHASE.DROP_IN_PROGRESS;
export const isDropFinished = (dropPhase: DROP_PHASE): boolean =>
  dropPhase === DROP_PHASE.DROP_FINISHED;

export type GiveawayActionType =
  | GiveawayInitializeAction
  | WinnerPickAction
  | NextDropAction
  | GiveawayEndingAction
  | GiveawayEndedAction
  | WaitingForWinnerAction
  | WinnerRevealedAction;

export type GiveawayInitializeAction = {
  type: GIVEAWAY_ACTION.STARTED;
  nextDropAt: Date;
  leftItemsToDrop: GiveawayItem[];
  lastWinner: Maybe<WinnerOfDrop>;
};

export type NextDropAction = {
  type: GIVEAWAY_ACTION.NEXT_DROP;
  nextDropAt: Date;
  leftItemsToDrop: GiveawayItem[];
  lastWinner: Maybe<WinnerOfDrop>;
};

export type WinnerPickAction = {
  type: GIVEAWAY_ACTION.WINNER_PICK;
  winner: WinnerOfDrop;
};

export type GiveawayEndedAction = {
  type: GIVEAWAY_ACTION.ENDED;
};

export type GiveawayEndingAction = {
  type: GIVEAWAY_ACTION.ENDING;
};

export type WaitingForWinnerAction = {
  type: GIVEAWAY_ACTION.COUNTDOWN_END;
};

export type WinnerRevealedAction = {
  type: GIVEAWAY_ACTION.WINNER_REVEALED;
};

export type GiveawayState = {
  isGiveawayLive: boolean;
  dropPhase: DROP_PHASE;
  winnerPhase: WINNER_PHASE;
  nextDropAt: Maybe<Date>;
  leftItemsToDrop: GiveawayItem[];
  lastWinner: Maybe<WinnerOfDrop>;
  winner: Maybe<WinnerOfDrop>;
};

export type GiveawayDispatch = (action: GiveawayActionType) => void;

export type WinnerOfDrop = {
  name: string;
  item: GiveawayItem;
};

export type GiveawayItem = {
  itemId: string;
  marketHashName: string;
  imageSteamId: string;
};
export interface DropData {
  nextDropAt: Date;
  leftItemsToDrop: GiveawayItem[];
  lastWinnerOfItem: Maybe<WinnerOfDrop>;
}

export enum NOTIFICATION_TYPE {
  GIVEAWAY_STARTED = "GiveawayStarted",
  WINNER_PICKED = "WinnerPicked",
  NEXT_DROP = "NextDrop",
  GIVEAWAY_ENDED = "GiveawayEnded",
}

export type Notification =
  | WinnerNotification
  | GiveawayDropNotification
  | GiveawayEndNotification;

export type WinnerNotification = {
  notificationId: string;
  type: NOTIFICATION_TYPE.WINNER_PICKED;
  data: WinnerOfDrop;
};

export type GiveawayDropNotification = {
  notificationId: string;
  type: NOTIFICATION_TYPE.GIVEAWAY_STARTED | NOTIFICATION_TYPE.NEXT_DROP;
  data: DropData;
};

export type GiveawayEndNotification = {
  notificationId: string;
  type: NOTIFICATION_TYPE.GIVEAWAY_ENDED;
};
