import React from "react";

import { GiveawayItem } from "../../../../../types";
import { Container, Headline, Image, Name } from "./RevealPrize.styles";

interface RevealPrizeProps {
  prize: GiveawayItem;
}

const RevealPrize: React.FC<RevealPrizeProps> = ({
  prize,
}: RevealPrizeProps) => {
  const { marketHashName, imageSteamId } = prize;

  return (
    <Container>
      <Headline>the prize item is</Headline>
      <Image imageId={imageSteamId} />
      <Name>{marketHashName}</Name>
    </Container>
  );
};

export default RevealPrize;
