import { useMemo, useRef, useState } from "react";

function useCountdown(startAt: Date): {
  seconds: number;
  start: () => void;
  stop: () => void;
} {
  const initSecondsLeft = useMemo(
    () => getTimeLeftInSeconds(startAt),
    [startAt],
  );
  const [seconds, setSeconds] = useState<number>(initSecondsLeft);
  const interval = useRef<number>();

  function clearInterval() {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = undefined;
    }
  }

  function start() {
    if (!interval.current) {
      interval.current = window.setInterval(() => {
        const secondsLeft = getTimeLeftInSeconds(startAt);
        setSeconds(secondsLeft);
      }, 1000);
    }
  }

  function stop() {
    clearInterval();
  }

  return { seconds, start, stop };
}

function getTimeLeftInSeconds(startAt: Date): number {
  const now = Date.now;
  const startTime = new Date(startAt).getTime();
  const timeLeft = Math.round(startTime - now());
  const secondLeft = Math.round(parseFloat((timeLeft / 1000).toFixed(0)));

  return secondLeft;
}

export default useCountdown;
