import { useEffect, useRef, useState } from "react";
import socketIOClient, { Socket } from "socket.io-client";

import { Maybe } from "../functional/maybe";
import { Notification } from "./types";

function useSocketNotification(streamerCode: string): {
  lastNotification: Maybe<Notification>;
} {
  const socketRef = useRef<Socket>();
  const [lastNotification, setLastNotification] =
    useState<Maybe<Notification>>(null);

  useEffect(() => {
    socketRef.current = socketIOClient(window.location.origin, {
      query: { code: streamerCode },
    });
    socketRef.current.on("NOTIFICATIONS", setLastNotification);
    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  return { lastNotification };
}

export default useSocketNotification;
