import styled from "styled-components";

import { COLOR } from "../../../../../variables";

export const Timer = styled.div<{ fontColor?: COLOR }>`
  color: ${(props) => props.fontColor ?? COLOR.WHITE};
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transition: color 250ms ease-in-out;
`;
