import React, { useCallback, useState } from "react";

import { Maybe } from "../../../../../../functional/maybe";
import config from "../../../../../config";
import { GiveawayItem, WinnerOfDrop } from "../../../../../types";
import { Container, Headline, ItemImage, Items, Loop } from "./Roulette.styles";

interface RouletteProps {
  items: GiveawayItem[];
  winner: Maybe<WinnerOfDrop>;
  onEnd: () => void;
}

const Roulette: React.FC<RouletteProps> = ({
  items,
  winner,
  onEnd,
}: RouletteProps) => {
  const list = items.slice(0, config.ROULETTE_MAX_LENGTH);
  const listLength = list.length;
  const prizeLandingIndex = listLength - 1;

  const [isLoopStopped, setIsLoopStopped] = useState(false);
  const [loopIndex, setLoopIndex] = useState(0);
  const baseNumberOfLoops = 2;
  const maximumNumberOfLoops =
    baseNumberOfLoops + config.ROULETTE_MAX_LENGTH - listLength;

  const onAnimationLoopEnd = useCallback(() => {
    setLoopIndex((prevIndex) => prevIndex + 1);
    if (winner && loopIndex > maximumNumberOfLoops) {
      setIsLoopStopped(true);
    }
  }, [winner, loopIndex]);

  const renderItems = useCallback(() => {
    if (winner) {
      const prizeIndex = list.findIndex(
        (item) => item.itemId === winner.item.itemId,
      );

      if (prizeIndex !== -1) {
        list.splice(prizeIndex, 1, list[prizeLandingIndex]);
      }

      list[prizeLandingIndex] = winner.item;
    }

    return list.map(({ itemId, imageSteamId }) => (
      <ItemImage key={itemId} imageId={imageSteamId} />
    ));
  }, [winner]);

  return (
    <Container>
      <Headline>dropping in progress...</Headline>
      <Items>
        <Loop
          isStopped={isLoopStopped}
          landingIndex={prizeLandingIndex}
          listLength={listLength}
          onAnimationIteration={onAnimationLoopEnd}
          onAnimationEnd={onEnd}
        >
          {renderItems()}
        </Loop>
        {/* for animation */}
        <Loop
          isStopped={isLoopStopped}
          landingIndex={prizeLandingIndex}
          listLength={listLength}
        >
          {renderItems()}
        </Loop>
        <Loop
          isStopped={isLoopStopped}
          landingIndex={prizeLandingIndex}
          listLength={listLength}
        >
          {renderItems()}
        </Loop>
      </Items>
    </Container>
  );
};

export default Roulette;
