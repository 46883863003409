import { length } from './iterable';
import { Predicate } from './function';

export const isNil = <T>(v: T | null | undefined): v is null | undefined => v === null || v === undefined;
export const isNotNil = <T>(v: T | null | undefined): v is T => v !== null && v !== undefined;
export const isNot = <T>(v: T | boolean): v is T => v === false;
export const isTrue = (v: boolean): v is true => v === true;
export const isFalse = (v: boolean): v is true => v === false;

export const assertUnreachable = (x: never): never => {
    throw new Error("Didn't expect to get here");
};

export const isEqual = <T>(a: T) => (b: T): boolean => a === b;

export const doesAllPass = <F extends Predicate, V extends any>(fn: F, arr: V[]): boolean =>
    length(arr.filter(fn)) === length(arr);

export const isGreaterEqualThan = (a: number) => (b: number): boolean => b >= a;
export const isGreaterThan = (a: number) => (b: number): boolean => b > a;
type hasKey<T> = { [K in keyof T]: T[K] };
export const hasGreaterValueOnKey = <T extends hasKey<T>, U extends hasKey<U>>(
    ob1: T,
    obj2: U,
    key: keyof hasKey<T> & keyof hasKey<U>
): boolean => ob1[key] > obj2[key];
export const isLesserEqualThan = (a: number) => (b: number): boolean => b <= a;
export const isLesserThan = (a: number) => (b: number): boolean => b < a;
