import styled from "styled-components";

import {
  SmallHeadlineText,
  MediumItemImage,
  MediumItemNameText,
} from "../../../../../styles";

export const Container = styled.div`
  padding: 20px 24px 0;
`;

export const Headline = styled(SmallHeadlineText)`
  text-align: center;
`;

export const Image = styled(MediumItemImage)`
  margin: 0 auto;
`;

export const Name = styled(MediumItemNameText)`
  text-align: center;
`;
