import React from "react";
import styled from "styled-components";

import { COLOR } from "../../../variables";
import Logotype from "./Logotype";

const Text = styled.div`
  color: ${COLOR.GREY};
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;

const Brand = styled.div`
  display: flex;
  margin: 0 0 0 10px;
`;

const Footer: React.FC = () => (
  <>
    <Text>powered by</Text>
    <Brand>
      <Logotype />
    </Brand>
  </>
);

export default Footer;
